























































import Page from '@/components/swine/layout/Page.vue';
import GeneralPage from '@/components/general/layout/GeneralPage.vue';
import InnerPageTemplate from '@/components/swine/layout/InnerPageTemplate.vue';
import DeleteModalTemplate from '@/components/swine/layout/DeleteModalTemplate.vue';
import UserFormTemplate from './UserFormTemplate.vue';
import { UserStores } from '@/store/swine/user/index';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { decryptData } from '@/utils/encryption';
import {
  Role,
  PoultryModuleTypes,
  PoultryAuditTypes,
  SwineModuleTypes,
  SwineAuditTypes,
} from '@/utils/constants';
import { ProfileStores } from '@/store/swine/profile/index';

@Component({
  components: {
    Page,
    InnerPageTemplate,
    UserFormTemplate,
    DeleteModalTemplate,
    GeneralPage,
  },
})
export default class EditUser extends Vue {
  store = UserStores.detail;
  showEditButtons = false;
  isModalOpen = false;
  currentRole = '';
  currentCountry = '';
  profileStore = ProfileStores.profile;
  poultryModuleTypes = PoultryModuleTypes;
  poultryAuditTypes = PoultryAuditTypes;
  swineModuleTypes = SwineModuleTypes;
  swineAuditTypes = SwineAuditTypes;

  mounted() {
    this.updatePermissions();
  }

  updatePermissions() {
    if (this.profileStore.userProfile) {
      const userProfile = this.profileStore.userProfile;
      // First, get the user ID then make a call to backend to retrieve it
      this.currentRole = userProfile.role;
      this.currentCountry = userProfile.country ? userProfile.country.code : '';
      this.store.setUserId(this.$route.params.userId);
      this.store.getUserDetails();
    }
  }

  @Watch('store.response')
  async fillUserDetails() {
    if (this.store.response.email) {
      let decryptedUserDetails = {
        ...this.store.response,
      };
      if (
        this.currentRole === Role.Admin ||
        this.currentRole === Role.Regional
      ) {
        const decryptedPassword = await decryptData(
          this.store.response.password
        );
        decryptedUserDetails = {
          ...decryptedUserDetails,
          password: decryptedPassword,
        };
      }
      this.store.setUserDetails(decryptedUserDetails);
      if (
        this.currentRole === Role.Admin &&
        this.store.response.country &&
        typeof this.store.response.country !== 'string' &&
        this.store.response.country.code === this.currentCountry
      ) {
        this.showEditButtons = true;
      }
      if (this.currentRole === Role.Regional) {
        this.showEditButtons = true;
      }
      if (
        this.currentRole === Role.Admin &&
        this.store.response.role === Role.Auditor &&
        typeof this.store.response.country !== 'string' &&
        this.store.response.country.code === this.currentCountry
      ) {
        this.showEditButtons = true;
      }
    }
  }

  updateRole(params: DropdownOption) {
    this.store.updateRole(params);
  }

  updatePhone(params: string) {
    this.store.updatePhone(params);
  }

  updateName(params: string) {
    this.store.updateName(params);
  }

  updateEmail(params: string) {
    this.store.updateEmail(params);
  }

  updatePassword(params: string) {
    this.store.updatePassword(params);
  }

  updateCountry(params: string) {
    this.store.updateCountry(params);
  }

  updateAuditType(params: number[]) {
    this.store.updateAuditType(params);
  }

  updateModuleType(params: number[]) {
    this.store.updateModuleType(params);
  }

  promptDeleteUser() {
    this.isModalOpen = true;
  }

  async editUser() {
    await this.store.updateUser();
  }

  async deleteUser(confirm: boolean) {
    if (confirm) {
      await this.store.deleteUser();
    }
    this.isModalOpen = false;
  }

  @Watch('store.response')
  checkAxiosResponse() {
    if (
      this.store.response &&
      (this.store.response.status === 200 || this.store.response.status === 204)
    ) {
      this.store.resetStore();

      this.$router.push({
        name: 'UserManagement',
      });
    }
  }

  get buttonDisabled() {
    if (this.store.userDetails.role === Role.Regional) {
      return (
        this.store.userDetails.name === '' ||
        this.store.userDetails.phoneNumber === '' ||
        this.store.userDetails.email === '' ||
        this.store.userDetails.password === '' ||
        !this.checkSpeciesAndAuditTypeAccess()
      );
    }
    return (
      this.store.userDetails.name === '' ||
      this.store.userDetails.phoneNumber === '' ||
      this.store.userDetails.email === '' ||
      this.store.userDetails.password === '' ||
      this.store.userDetails.role === '' ||
      this.store.userDetails.country === '' ||
      !this.store.userDetails.country ||
      !this.checkSpeciesAndAuditTypeAccess()
    );
  }

  checkSpeciesAndAuditTypeAccess(): boolean {
    if (
      this.store.userDetails.moduleTypeIds!.length === 0 ||
      this.store.userDetails.auditTypeIds!.length === 0
    ) {
      return false;
    }
    let isSwineValid = false;
    let isPoultryValid = false;
    if (
      (this.swineModuleTypes.some((moduleType) =>
        this.store.userDetails.moduleTypeIds!.includes(moduleType.value)
      ) === true &&
        this.swineAuditTypes.some((auditType) =>
          this.store.userDetails.auditTypeIds!.includes(auditType.value)
        ) === true) ||
      (this.swineModuleTypes.some((moduleType) =>
        this.store.userDetails.moduleTypeIds!.includes(moduleType.value)
      ) === false &&
        this.swineAuditTypes.some((auditType) =>
          this.store.userDetails.auditTypeIds!.includes(auditType.value)
        ) === false)
    ) {
      isSwineValid = true;
    }
    if (
      (this.poultryModuleTypes.some((moduleType) =>
        this.store.userDetails.moduleTypeIds!.includes(moduleType.value)
      ) === true &&
        this.poultryAuditTypes.some((auditType) =>
          this.store.userDetails.auditTypeIds!.includes(auditType.value)
        ) === true) ||
      (this.poultryModuleTypes.some((moduleType) =>
        this.store.userDetails.moduleTypeIds!.includes(moduleType.value)
      ) === false &&
        this.poultryAuditTypes.some((auditType) =>
          this.store.userDetails.auditTypeIds!.includes(auditType.value)
        ) === false)
    ) {
      isPoultryValid = true;
    }
    return isSwineValid && isPoultryValid;
  }
}
