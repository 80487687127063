

















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({})
export default class InnerPageTemplate extends Vue {
  @Prop({
    type: String,
    default: 'Default',
  })
  contentTitle!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  alignLeft!: boolean;

  @Prop({
    type: Boolean,
    default: true,
  })
  noRouterRedirect!: boolean;

  goBack() {
    if (this.noRouterRedirect) {
      this.$router.back();
    } else {
      this.returnToSpecificDestination();
    }
  }

  @Emit(`return`)
  returnToSpecificDestination() {
    return true;
  }
}
