import {
  Module,
  VuexModule,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store/swine';
import { User, UserResponse } from '@/models/swine/user/user.model';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';

export interface PaginatedRequestParams {
  limit?: number;
  offset?: number;
  filterBy?: string;
  sortBy?: string;
  country?: string;
}

interface SearchRequestParams extends PaginatedRequestParams {
  search: string;
}

@Module({
  namespaced: true,
  dynamic: true,
  name: 'user.list',
  store,
})
class UserList extends VuexModule {
  paginationResponse: UserResponse | null = null;
  response: User[] | null = null;
  isLoading = false;
  error: any | null = null;
  sortString = '';

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setError(error: any) {
    this.error = error;
  }

  @Mutation
  setResponse(payload: User[]) {
    this.response = payload;
  }

  @Mutation
  setPaginationResponse(payload: UserResponse) {
    this.paginationResponse = payload;
  }

  @Mutation
  setSortString(sortString: string) {
    this.sortString = sortString;
  }

  @Action
  // This function returns pagination details
  async getPaginatedUsers(params: PaginatedRequestParams) {
    this.context.commit('updateLoadingStatus', true);
    try {
      const response = await axios.get<UserResponse>(Endpoints.Users, {
        params,
      });
      if (response.data) {
        this.context.commit('setPaginationResponse', response.data);
      }
    } catch (error) {
      this.context.commit('setError', error.data);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  // This does not return any pagination details
  async getUsers() {
    this.context.commit('updateLoadingStatus', true);
    try {
      const response = await axios.get<User[]>(Endpoints.Users);
      if (response.data) {
        this.context.commit('setResponse', response.data);
      }
    } catch (error) {
      this.context.commit('setError', error.data);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async searchUsers(params: SearchRequestParams) {
    this.context.commit('updateLoadingStatus', true);
    try {
      const response = await axios.get<UserResponse>(
        `${Endpoints.Users}/`,
        {
          params,
        },
      );
      if (response.data) {
        this.context.commit('setPaginationResponse', response.data);
      }
    } catch (error) {
      this.context.commit('setError', error.data);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(UserList);
