function fromHex(hexString: any) {
  return new Uint8Array(
    hexString.match(/.{1,2}/g).map((byte: string) => parseInt(byte, 16)),
  );
}

function fromBase64(base64String: string) {
  return Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));
}

export async function decryptData(encryptedData: string) {
  const textParts = encryptedData.split(':');
  const rawKey = fromHex(textParts[0]);
  const iv = fromHex(textParts[1]);
  const ciphertext = fromBase64(textParts[2]).slice(16);

  try {
    const key = await window.crypto.subtle.importKey(
      'raw',
      rawKey,
      'AES-CBC',
      true,
      ['encrypt', 'decrypt'],
    );

    const decrypted = await window.crypto.subtle.decrypt(
      {
        name: 'AES-CBC',
        iv: iv,
      },
      key,
      ciphertext,
    );

    const decoder = new TextDecoder();
    const plaintext = decoder.decode(decrypted);

    return plaintext;
  } catch (err) {
    console.log(err.name);
  }
}
