






















import { Component, Vue, Emit, Prop } from 'vue-property-decorator';

@Component({})
export default class DeleteModalTemplate extends Vue {
  @Prop({
    type: String,
    default: null,
  })
  modalTitle!: String;

  @Emit('delete')
  emitDelete() {
    return true;
  }

  @Emit('closeForm')
  closeForm() {
    return false;
  }
}
