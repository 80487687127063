// eslint-disable-next-line no-useless-escape
const regex = {
  EMAIL_REGEX: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  URL_REGEX: /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/,

  PHONE_NUMBER_REGEX: /^[+][0-9]+[\b]*$/,

  PASSWORD_REGEX: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,

  NUMBER_ONLY_REGEX: /^(0|[1-9][0-9]*(\.[0-9]+)?|0\.[0-9]+)$/,
};

export const errorMessages = {
  NAME_ERROR: 'User name cannot be empty',
  CLIENT_NAME_ERROR: 'Client name cannot be empty',
  FARM_NAME_ERROR: 'Farm name cannot be empty',
  EMAIL_ERROR: 'Email cannot be empty & must be in a valid format',
  PHONE_ERROR: 'Phone number cannot be empty & must be in a valid format',
  CLIENT_PHONE_ERROR: 'Phone number must be in a valid format',
  PASSWORD_ERROR:
    'Password must have at least 8 characters with 1 uppercase letter and 1 number',
  LOGIN_ERROR: '* Incorrect email or password',
  COUNTRY_ERROR: 'Please select a country to proceed.',
  REGION_ERROR: 'Please select a region to proceed.',
  STATE_ERROR: 'Please select a state to proceed.',
  CITY_ERROR: 'Please select a city to proceed.',
  EMAIL_CONFLICT_ERROR:
    '* This user already exists. Please enter a different email address.',
};

export function validatePhoneNumber(phoneNumber: string) {
  const phoneRegex = regex.PHONE_NUMBER_REGEX;
  if (phoneRegex.test(phoneNumber)) {
    return true;
  }
  return false;
}

export function validateEmail(email: string) {
  const emailRegex = regex.EMAIL_REGEX;
  if (emailRegex.test(email)) {
    return true;
  }
  return false;
}

export function validatePassword(password: string) {
  const passwordRegex = regex.PASSWORD_REGEX;
  if (passwordRegex.test(password)) {
    return true;
  }
  return false;
}

export function validateInputNumber(inputNumber: string) {
  const inputNumberRegex = regex.NUMBER_ONLY_REGEX;
  if (inputNumberRegex.test(inputNumber)) {
    return true;
  }
  return false;
}
