import {
  Module,
  VuexModule,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store/swine';
import { User } from '@/models/swine/user/user.model';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';
import { ToastProgrammatic as Toast } from 'buefy';

@Module({
  namespaced: true,
  dynamic: true,
  name: 'user.detail',
  store,
})
class UserDetail extends VuexModule {
  response: any | null = null;
  isLoading = false;
  error: any | null = null;
  userDetails: Partial<User> = {
    id: 1,
    publicId: '',
    role: '',
    name: '',
    phoneNumber: '',
    email: '',
    password: '',
    country: '',
    auditTypeIds: [],
    moduleTypeIds: [],
    auditType: [],
    module: [],
  };
  userId = '';

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setError(error: any) {
    this.error = error;
  }

  @Mutation
  setResponse(payload: any) {
    this.response = payload;
  }

  @Mutation
  setUserId(payload: string) {
    this.userId = payload;
  }

  @Mutation
  setUserDetails(payload: User) {
    const auditTypeIds = payload.auditType.map(
      (auditType: any) => auditType.id
    );
    const moduleTypeIds = payload.module.map((module: any) => module.id);
    this.userDetails = {
      ...payload,
      name: payload.userProfile.name,
      phoneNumber: payload.userProfile.phoneNumber,
      auditTypeIds,
      moduleTypeIds,
    };
  }

  @Mutation
  updateRole(payload: DropdownOption) {
    if (payload && payload.value) {
      this.userDetails.role = payload.value;
    }
  }

  @Mutation
  updatePhone(payload: string) {
    this.userDetails.phoneNumber = payload;
  }

  @Mutation
  updateName(payload: string) {
    this.userDetails.name = payload;
  }

  @Mutation
  updateEmail(payload: string) {
    this.userDetails.email = payload;
  }

  @Mutation
  updatePassword(payload: string) {
    this.userDetails.password = payload;
  }

  @Mutation
  updateCountry(payload: string) {
    this.userDetails.country = payload;
    this.userDetails.countryId = payload;
  }

  @Mutation
  updateAuditType(payload: number[]) {
    this.userDetails.auditTypeIds = payload;
  }

  @Mutation
  updateModuleType(payload: number[]) {
    this.userDetails.moduleTypeIds = payload;
  }

  @Mutation
  resetUserDetails() {
    this.userDetails = {
      id: 1,
      publicId: '',
      role: '',
      name: '',
      phoneNumber: '',
      email: '',
      password: '',
      country: '',
      auditTypeIds: [],
      moduleTypeIds: [],
      auditType: [],
      module: [],
    };
  }

  @Action
  async getUserDetails() {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      axios
        .get(`${Endpoints.Users}/${this.userId}`)
        .then((response) => this.context.commit('setResponse', response.data));
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async updateUser() {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      axios
        .put(`${Endpoints.Users}/${this.userId}`, this.userDetails)
        .then((response) => {
          this.context.commit('setResponse', response);
          if (response.status === 200) {
            // Show toast
            Toast.open({
              type: 'is-dark',
              message: `User details have successfully updated`,
              duration: 3000, // 3 seconds
            });
          }
        });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  async deleteUser() {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      axios.delete(`${Endpoints.Users}/${this.userId}`).then((response) => {
        this.context.commit('setResponse', response);
        if (response.status === 204) {
          // Show toast
          Toast.open({
            type: 'is-dark',
            message: `${this.userDetails.email} has been deleted`,
            duration: 3000, // 3 seconds
          });
        }
      });
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  resetStore() {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      this.resetUserDetails();
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(UserDetail);
