import {
  Module,
  VuexModule,
  Action,
  Mutation,
  getModule,
} from 'vuex-module-decorators';
import store from '@/store/swine';
import { User } from '@/models/swine/user/user.model';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';

@Module({
  namespaced: true,
  dynamic: true,
  name: 'user.create',
  store,
})
class UserCreate extends VuexModule {
  response: any | null = null;
  isLoading = false;
  error: any | null = null;
  userDetails: Partial<User> = {
    role: '',
    name: '',
    phoneNumber: '',
    email: '',
    password: '',
    countryId: '',
    auditTypeIds: [],
    moduleTypeIds: [],
  };

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setError(error: any) {
    this.error = error;
  }

  @Mutation
  setResponse(payload: any) {
    this.response = payload;
  }

  @Mutation
  updateRole(payload: DropdownOption) {
    if (payload && payload.value) {
      this.userDetails.role = payload.value;
    }
  }

  @Mutation
  updatePhone(payload: string) {
    this.userDetails.phoneNumber = payload;
  }

  @Mutation
  updateName(payload: string) {
    this.userDetails.name = payload;
  }

  @Mutation
  updateEmail(payload: string) {
    this.userDetails.email = payload;
  }

  @Mutation
  updatePassword(payload: string) {
    this.userDetails.password = payload;
  }

  @Mutation
  updateCountry(payload: string) {
    this.userDetails.countryId = payload;
  }

  @Mutation
  updateAuditType(payload: number[]) {
    this.userDetails.auditTypeIds = payload;
  }

  @Mutation
  updateModuleType(payload: number[]) {
    this.userDetails.moduleTypeIds = payload;
  }

  @Mutation
  resetUserDetails() {
    this.userDetails = {
      role: '',
      name: '',
      phoneNumber: '',
      email: '',
      password: '',
      countryId: '',
      auditTypeIds: [],
      moduleTypeIds: [],
    };
  }

  @Action
  async createUser() {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      axios
        .post<any>(Endpoints.Users, this.userDetails)
        .then((response) => this.context.commit('setResponse', response));
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }

  @Action
  resetStore() {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      this.resetUserDetails();
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}

export default getModule(UserCreate);
