import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/swine';
import { axios } from '@/utils/axios';
import { Endpoints } from '@/utils/endpoints';
import { Country } from '@/models/swine/country/country.model';

@Module({
  namespaced: true,
  dynamic: true,
  name: 'country.list',
  store,
})
class CountryListStore extends VuexModule {
  response: Country[] | null = null;
  isLoading = false;
  error: any | null = null;

  @Mutation
  updateLoadingStatus(payload: boolean) {
    this.isLoading = payload;
  }

  @Mutation
  setError(error: any) {
    this.error = error;
  }

  @Mutation
  setResponse(payload: Country[]) {
    this.response = payload;
  }

  @Mutation
  resetStore() {
    this.response = null;
    this.error = null;
  }

  @Action
  getAllCountries() {
    this.context.commit('updateLoadingStatus', true);
    this.context.commit('setError', null);
    try {
      axios
        .get(`${Endpoints.Countries}`)
        .then((response) => this.context.commit('setResponse', response.data));
    } catch (error) {
      this.context.commit('setError', error);
    }
    this.context.commit('updateLoadingStatus', false);
  }
}
export default getModule(CountryListStore);
