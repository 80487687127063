


























































































































































































































import Dropdown from '@/components/swine/DropdownAnalysisTemplate.vue';
import {
  DEFAULT_USER_ROLES,
  Role,
  PoultryAuditTypes,
  SwineModuleTypes,
  PoultryModuleTypes,
  SwineAuditTypes,
} from '@/utils/constants';
import {
  errorMessages,
  validateEmail,
  validatePassword,
} from '@/utils/validation';
import { DropdownOption } from '@/models/swine/dropdown.model';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  User,
  VuePhoneNumberInputInterface,
} from '@/models/swine/user/user.model';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { CountryStores } from '@/store/swine/country';
import { Country } from '@/models/swine/country/country.model';
import { generateUniqueId } from '@/utils/lodash';
import { UserStores } from '@/store/swine/user';
import { UserSessionStore } from '@/store/swine/user-session';
import { ProfileStores } from '@/store/swine/profile/index';

@Component({
  components: {
    Dropdown,
    VuePhoneNumberInput,
  },
})
export default class UserFormTemplate extends Vue {
  // We will destructure the prop and return updated values rather than mutating props (better practice)
  @Prop({
    type: Object,
    default: null,
  })
  userDetails!: Partial<User>;

  @Prop({
    type: Boolean,
    default: false,
  })
  emailConflict!: boolean;

  role = '';
  phoneNumber = '';
  name = '';
  email = '';
  password = '';
  country = '';
  auditTypeIds: number[] = [];
  moduleTypeIds: number[] = [];
  poultryModuleTypes = PoultryModuleTypes;
  poultryAuditTypes = PoultryAuditTypes;
  swineModuleTypes = SwineModuleTypes;
  swineAuditTypes = SwineAuditTypes;

  roles = DEFAULT_USER_ROLES;
  errorMessages = errorMessages;

  nameError = false;
  phoneError = false;
  emailError = false;
  passwordError = false;

  roleTextValue = 'Select';
  countryTextValue = 'Select';
  phoneNumberCountryCodes = [
    'MY',
    'PH',
    'ID',
    'TH',
    'VN',
    'KH',
    'MM',
    'LA',
    'CN',
    'BN',
    'TW'
  ];

  isCountryDropdownLock = false;
  countries: DropdownOption[] = [];
  store = CountryStores.list;
  selectedCountryIndex: number | null = null;
  isCurrentRoleRegional = false;
  userStore = UserStores.detail;
  currentCountryPhoneCode = 'MY';
  isAccessValid = true;
  currentRole = '';
  currentCountry = '';
  userSessionDetails = UserSessionStore.detail;
  userProfileStore = ProfileStores.profile;

  mounted() {
    this.updatePermissions();
  }

  updatePermissions() {
    if (this.userProfileStore.userProfile) {
      this.currentRole = this.userProfileStore.userProfile.role;
      this.currentCountry = this.userProfileStore.userProfile.country
        ? this.userProfileStore.userProfile.country.code
        : '';
      if (this.currentRole === Role.Admin) {
        this.roles = this.roles.filter((data) => {
          return data.value === 'viewer' || data.value === 'auditor';
        });
        this.isCountryDropdownLock = true;
      }
      this.store.getAllCountries();
    }
  }

  getCountryPhoneCode() {
    if (this.phoneNumber) {
      const phoneCountryCode = this.phoneNumber.substr(0, 3);
      let output = '';
      switch (phoneCountryCode) {
        case '+60': {
          output = 'MY';
          break;
        }
        case '+63': {
          output = 'PH';
          break;
        }
        case '+62': {
          output = 'ID';
          break;
        }
        case '+66': {
          output = 'TH';
          break;
        }
        case '+84': {
          output = 'VN';
          break;
        }
        case '+855': {
          output = 'KH';
          break;
        }
        case '+95': {
          output = 'MM';
          break;
        }
        case '+856': {
          output = 'LA';
          break;
        }
        case '+86': {
          output = 'CN';
          break;
        }
        case '+673': {
          output = 'BN';
          break;
        }
        case '+886': {
          output = 'TW';
          break;
        }
        default: {
          output = 'MY';
          break;
        }
      }
      return output;
    }
    return 'MY';
  }

  @Watch(`store.response`)
  getCountries() {
    if (this.store.response) {
      this.countries = [];
      this.selectedCountryIndex = this.store.response.findIndex(
        (data: Country) => {
          return data.code === this.currentCountry;
        }
      );
      this.store.response.map((data: Country) => {
        this.countries.push({
          text: data.name,
          value: String(data.id),
          uniqueKey: generateUniqueId(`${data.code}_`),
        });
      });
      if (this.selectedCountryIndex > -1) {
        this.country = this.countries[this.selectedCountryIndex].value;
      }
      this.validateAccess();
    }
  }

  @Watch('userDetails', { deep: true })
  updateAllFields() {
    if (
      this.userDetails.countryId &&
      this.store.response &&
      this.userProfileStore.userProfile
    ) {
      this.selectedCountryIndex = this.store.response.findIndex(
        (data: Country) => {
          if (this.userDetails.countryId !== undefined) {
            return data.id === parseInt(this.userDetails.countryId);
          }
        }
      );
      if (
        this.selectedCountryIndex > -1 &&
        this.countries[this.selectedCountryIndex]
      ) {
        this.country = this.countries[this.selectedCountryIndex].value;
      }
    }
    if (this.userDetails.role) {
      this.role = this.userDetails.role;
    }
    if (this.userDetails.name) {
      this.name = this.userDetails.name;
    }
    if (this.userDetails.phoneNumber) {
      this.phoneNumber = this.userDetails.phoneNumber;
      this.currentCountryPhoneCode = this.getCountryPhoneCode();
    }
    if (this.userDetails.email) {
      this.email = this.userDetails.email;
    }
    if (this.userDetails.password) {
      this.password = this.userDetails.password;
    }
    if (this.userDetails.auditTypeIds) {
      this.auditTypeIds = this.userDetails.auditTypeIds;
    }
    if (this.userDetails.moduleTypeIds) {
      this.moduleTypeIds = this.userDetails.moduleTypeIds;
    }
    this.validateAccess();
  }

  validateAccess() {
    if (this.currentRole === Role.Viewer) {
      this.isAccessValid = false;
    }

    if (this.currentRole === Role.Regional) {
      this.isAccessValid = true;
    }

    if (
      this.userProfileStore.userProfile &&
      this.userProfileStore.userProfile.role === Role.Auditor
    ) {
      this.isAccessValid = false;
    }

    if (
      this.countries.length > 0 &&
      this.userDetails &&
      this.currentRole === Role.Admin
    ) {
      if (this.role === Role.Regional) {
        this.isAccessValid = false;
      } else if (
        this.selectedCountryIndex &&
        this.store.response &&
        this.store.response[this.selectedCountryIndex] &&
        this.currentCountry !==
          this.store.response[this.selectedCountryIndex].code
      ) {
        this.isAccessValid = false;
      }
    }
  }

  @Emit('roleChange')
  updateRole(params: DropdownOption) {
    this.roleTextValue = params.text;
    this.isCurrentRoleRegional = params.value === Role.Regional ? true : false;
    return params;
  }

  @Emit('nameChange')
  updateName(params: string) {
    if (params === '') {
      this.nameError = true;
    } else {
      this.nameError = false;
    }
    return params;
  }

  @Emit('phoneChange')
  updatePhone(params: VuePhoneNumberInputInterface) {
    this.phoneError = !params.isValid ? true : false;
    return this.phoneError ? '' : params.formattedNumber;
  }

  @Emit('emailChange')
  updateEmail(params: string) {
    this.emailError = !validateEmail(params);
    if (this.emailError) {
      return '';
    }
    return params;
  }

  @Emit('passwordChange')
  updatePassword(params: string) {
    this.passwordError = !validatePassword(params);
    if (this.passwordError) {
      return '';
    }
    return params;
  }

  @Emit('countryChange')
  updateCountryId(data: DropdownOption) {
    if (data) {
      this.countryTextValue = data.text;
      return data.value;
    }
  }

  @Emit('auditTypeChange')
  updateAuditType() {
    return this.auditTypeIds;
  }

  @Emit('moduleTypeChange')
  updateModuleType() {
    return this.moduleTypeIds;
  }
}
